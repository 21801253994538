import { get, post, put, Delete } from "../axios";

// 内容列表-内容分类（用于内容列表查询和新增编辑）
export const contentListClassify = (params) =>
  get("/web/content/list/classify", params);

// 内容列表-列表
export const contentManageList = (params) =>
  get("/web/content/manage/list", params);

// 内容列表-新增
export const contentManageAdd = (params) =>
  post("/web/content/manage/add", params);

// 内容列表-编辑
export const contentManageUpdate = (params) =>
  put("/web/content/manage/update", params);

// 内容列表-删除
export const contentManageDelete = (id) =>
  Delete("/web/content/manage/delete/" + id, "");

// 内容列表-详情
export const contentManageView = (id) =>
  get("/web/content/manage/view/" + id, "");
